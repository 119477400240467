<template>
  <div class="card myTopMargin">
    <div class="card-header" id="transportHeading">
      <div class="row align-items-center my-2">
        <div class="col-auto">
          <div class="form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="transportAllowedSwitch"
                   name="transport-allowed" @click="toggleVisibility()" v-model="isVisible">
          </div>
        </div>
        <div class="col-auto">
          <h3>Doprava</h3>
        </div>
      </div>
    </div>
    <div class="card-body row g-3">
      <fieldset class="row g-3" v-show="isVisible">
        <div class="row gy-2">
          <div class="form-group col-sm-12 col-md-3 position-relative">
            <label for="distance">Vzdálenost, tam i zpět (km):</label>
            <input type="number" step="0.1" min="0" class="form-control" id="distance" name="distance"
                   v-model="transportationInternal.distance">
            <span class="position-absolute top-15 end-0 translate-middle-y me-3" data-bs-toggle="tooltip"
                  data-bs-placement="right" title="Zadejte vzdálenost v kilometrech pro cestu tam i zpět. Pokud pojede více vozidel vynásobte vzdálenost jejich počtem. Tj. je-li vzdálenost 100 km a počet vozidel 2 pak výsledná hodnota je 2 x 100 = 200 km.">
              <i class="bi bi-question-circle"></i>
            </span>
          </div>

          <div class="form-group col-sm-12 col-md-5 position-relative">
            <label for="distance-from-parking">Vzdálenost k místu instalace (m):</label>
            <input type="number" step="1" min="0" class="form-control" id="distance-from-parking"
                   name="distance-from-parking"
                   v-model="transportationInternal.distanceFromParking">
            <span class="position-absolute top-15 end-0 translate-middle-y me-3" data-bs-toggle="tooltip"
                  data-bs-placement="right" title="0 znamená zaparkováno před vchodem, při zadávání vzdálenosti k místu instalace je nutné brát v úvahu i velikost budovy atd. Měla by vyjadřovat skutečnou vzdálenost od automobilu k místu, kde se bude provádět instalace včetně vzdáleností v rámci budovy.">
              <i class="bi bi-question-circle"></i>
            </span>
          </div>
        </div>
        <div class="row gy-2">
          <div class="form-group col-sm-12 col-md-3">
            <label for="floor-with-lift">Patra s výtahem:</label>
            <input type="number" step="1" min="0" class="form-control" id="floor-with-lift" name="floor-with-lift"
                   v-model="transportationInternal.floorWithLift">
          </div>
          <div class="form-group col-sm-12 col-md-3">
            <label for="floor-without-lift">Patra bez výtahu:</label>
            <input type="number" step="1" min="0" class="form-control" id="floor-without-lift"
                   name="floor-without-lift" v-model="transportationInternal.floorWithoutLift">
          </div>
        </div>
        <div class="row gy-2">
          <h5>Vyber dílce pro přepravu:</h5>
          <div class="form-group">
            <CalculationItemsSelector :caption="'transportation'"
                                      :calculation-items="calculationItems"
                                      :selected-items="transportationInternal.itemList"/>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import Transportation from "@/models/Transportation";
import CalculationItemsSelector from "@/components/items/CalculationItemsSelector.vue";

export default {
  name: "CalculationTransport",
  components: {
    CalculationItemsSelector
  },
  props: ['calculationItems', 'transportation', 'cid'],
  emits: ['update:transportation'],
  data() {
    return {
      isVisible: false,
      transportationInternal: {},
    }
  },
  methods: {
    setVisibility(item) {
      this.isVisible = item !== null;
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible
      if (this.isVisible) {
        this.$emit('update:transportation', this.transportationInternal)
      } else {
        this.$emit('update:transportation', null)
      }
    }
  },
  watch: {
    transportationInternal: {
      handler(newVal) {
        // console.log(newVal)
        if (this.isVisible) {
          this.$emit('update:transportation', newVal)
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.transportationInternal = this.transportation ? this.transportation : new Transportation({
      calculation_uid: this.cid,
      floor_with_lift: 0,
      floor_without_lift: 0,
      distance_from_parking: 0,
      distance: 0,
      itemList: []
    })
  },
  mounted() {
    this.setVisibility(this.transportation)
  }
}
</script>

<style scoped>
.top-15 {
  top: 15%;
}
</style>
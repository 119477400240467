import {createStore} from 'vuex'
import CalculationStoreModule from "@/store/modules/calculation";
import CalculationItemsStore from "@/store/modules/calculation_items";
import myAxiosBackend from "@/axios";
import VueCookie from 'vue-cookie'
// import router from "@/router";

const SECRET = process.env.VUE_APP_SECRETS
let timer;
let CryptoJS = require('crypto-js');

function encrypt(text) {
  if (!text) return null
  return CryptoJS.AES.encrypt(text, SECRET).toString()
}

function decrypt(text) {
  if (!text) return null
  let bytes = CryptoJS.AES.decrypt(text, SECRET);
  return bytes.toString(CryptoJS.enc.Utf8)
}

const tosFrontEndStore = createStore({
  modules: {
    calculation: CalculationStoreModule,
    items: CalculationItemsStore
  },
  state() {
    return {
      user: null,
      userToken: null,
      tokenExpireIn: null,
      isAuthenticated: false,
      doingAutoLogin: null
    }
  },
  getters: {
    getAccessToken(state) {
      return state.userToken
    },
    getUser(state) {
      return state.user
    },
    getIsAuthenticated(state) {
      return state.isAuthenticated
    },
    getTokenExpireIn(state) {
      return state.tokenExpireIn
    },
    getDoingAutoLogin(state) {
      return state.doingAutoLogin
    }
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.userToken = accessToken
    },
    setUser(state, payload) {
      state.user = payload
    },
    logout(state) {
      state.user = null;
      state.userToken = null;
      state.tokenExpireIn = null;
      state.isAuthenticated = false;
      clearTimeout(timer)
      VueCookie.delete('tos');
      VueCookie.delete('tos-expires-in')
      myAxiosBackend.defaults.headers.common['Authorization'] = null
    },
    setAuthenticated(state, payload) {
      state.isAuthenticated = payload
    },
    setTokenExpireIn(state, payload) {
      state.tokenExpireIn = payload;
    }
  },
  actions: {
    autoLoginUser(context) {
      const accessToken = decrypt(VueCookie.get('tos'));
      const expiresIn = VueCookie.get('tos-expires-in')
      const actualDate = new Date().getTime()
      if (!accessToken) {
        context.commit('logout');
        return false
      }
      if (!expiresIn || expiresIn < actualDate) {
        context.commit('logout');
        return false
      }

      const response = context.dispatch('getUserInfo', accessToken)
        .then((result) => {
          if (result) {
            context.commit('setUser', result)
            if (context.state.user.is_active) {
              context.commit('setAuthenticated', true);
              context.commit('setAccessToken', accessToken);
              timer = setTimeout(() => {
                context.dispatch('refreshToken', accessToken)
              }, (expiresIn - actualDate) * 0.9)
              myAxiosBackend.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
              return true
            } else {
              context.commit('logout');
              return false
            }
          }
        })
        .catch(() => false)
      context.state.doingAutoLogin = response
      return response
    },
    loginUser(context, payload) {
      const formData = new FormData();
      formData.set('username', payload.username);
      formData.set('password', payload.password);
      const response = myAxiosBackend.post('/auth/login', formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((response) => {
          if (response.statusText === "OK") {
            let accessToken = response.data.access_token
            const expirationDate = new Date().getTime() + (+response.data.expires_in * 1000);
            context.commit('setAccessToken', accessToken)
            context.commit('setTokenExpireIn', expirationDate)
            return context.dispatch('getUserInfo', accessToken)
              .then((result) => {
                if (result) {
                  context.commit('setUser', result)
                  if (context.state.user.is_active) {
                    context.commit('setAuthenticated', true)
                    VueCookie.set('tos', encrypt(accessToken), {secure: false})
                    VueCookie.set('tos-expires-in', expirationDate, {secure: false})
                    myAxiosBackend.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                    timer = setTimeout(() => {
                      context.dispatch('refreshToken', accessToken)
                    }, ((+response.data.expires_in) * 1000) * 0.9)
                    return true
                  }
                  return false
                }
                return false
              })
          }
        })
      context.state.doingAutoLogin = response
      return response
    },
    logoutUser(context) {
      const accessToken = context.getters.getAccessToken ? context.getters.getAccessToken : decrypt(VueCookie.get('tos'))
      if (accessToken) {
        return myAxiosBackend.post('/auth/logout', {}, {
          headers: {Authorization: `Bearer ${accessToken}`}
        }).then((response) => {
          if (response.status === 204 || response.status === 200) {
            context.commit('logout')
          }
        })
      }
    },
    getUserInfo(context, accessToken) {
      // const accessToken = context.getters.getAccessToken
      if (accessToken) {
        return myAxiosBackend.get('/users/me', {
          headers: {Authorization: `Bearer ${accessToken}`}
        }).then((response) => {
          if (response.statusText === 'OK') {
            return response.data
          } else {
            return null
          }
        })
      }
    },
    refreshToken(context, accessToken) {
      if (accessToken) {
        return myAxiosBackend.get('/auth/token', {
          headers: {Authorization: `Bearer ${accessToken}`}
        })
          .then((response) => {
            if (response.statusText === 'OK') {
              const newAccessToken = response.data.access_token
              const expirationDate = new Date().getTime() + (+response.data.expires_in * 1000);
              context.commit('setAccessToken', newAccessToken);
              context.commit('setTokenExpireIn', expirationDate);
              VueCookie.set('tos', encrypt(newAccessToken), {secure: false})
              VueCookie.set('tos-expires-in', expirationDate, {secure: false})
              timer = setTimeout(() => {
                context.dispatch('refreshToken', newAccessToken)
              }, ((+response.data.expires_in) * 1000) * 0.9)
            }
          })
          .catch(() => context.commit('logout'))
      }
    }
  },
})

export default tosFrontEndStore;

<template>
<div class="container">
  NestView
</div>
</template>

<script>
export default {
  name: "NestView",
  props: ["cid"],
}
</script>



<style scoped>

</style>
<template>
  <div class="container">
    <div class="row">
      <div class="col-auto my-5">
        <h1>Výpočet kalkulace</h1>
      </div>
    </div>
    <div class="card myTopMargin">
      <div class="card-header">
        <div class="row my-2">
          <h3>Základní informace</h3>
        </div>

      </div>
      <div class="card-body">
        <div class="form-group col-md-2">
          <label for="thickness" class="form-label">Zvol DPH:</label>
          <select v-model="selectedVat" class="form-select" id="vat" name="vat">
            <option value="-1" disabled selected>vyber DPH...</option>
            <option v-for="text, key in vat" :key="key" :value="key">
              {{ text }}
            </option>
          </select>
        </div>
        <div class="form-group my-3">
          <div class="form-check form-switch">
            <label class="form-check-label" for="cutoff">Nezapočítat dořez</label>
            <input class="form-check-input" type="checkbox" role="switch" id="cutoff"
                   name="cutoff" v-model="cutoffMaterialInclude">
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row justify-content-end">
          <div class="col-auto">
            <a class="btn btn-success" @click="doCalculate" :class="calculateBtnDisable">Vypočítej</a>
          </div>
        </div>
      </div>
    </div>
    <CalculationCalculateTable v-if="isCalculated"
                               :calculated-data="calculatedData"
                               :calculation="calculation"/>
  </div>
</template>

<script>
import CalculationCalculateTable from "@/components/calculation/CalculationCalculateTable.vue";
import myApi from "@/api/api";

export default {
  name: "CalculationCalculateView",
  components: {
    CalculationCalculateTable
  },
  props: ['cid'],
  data() {
    return {
      calculation: null,
      vat: {
        0: '0 %',
        12: '12 %',
        21: '21 %',
      },
      selectedVat: -1,
      calculatedData: null,
      isCalculated: false,
      calculateBtnDisable: 'disabled',
      cutoffMaterialInclude: false,
    }
  },
  methods: {
    async doCalculate() {
      this.isCalculated = false;
      const response = await myApi.calcualteCalculation(this.cid, this.vat, this.cutoffMaterialInclude);
      console.log(response);
      this.$store.dispatch('calculation/calculateCalculation', {cid: this.cid, vat: this.selectedVat, cutoff: !this.cutoffMaterialInclude})
          .then((response) => {
            this.isCalculated = true;
            this.calculatedData = response;
          })
    }
  },
  watch: {
    selectedVat:{
      handler(newValue) {
        if (newValue !== -1) {
          this.calculateBtnDisable = "";
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.$store.dispatch('calculation/setCalculationById', this.cid).then(() => {
      this.calculation = this.$store.getters['calculation/getCalculation']
    })
  }
}
</script>

<style scoped>
</style>